.App {
	text-align: center;
	position:relative;
}

.App-logo {
	height: 40vmin;
	user-select: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-link {
	color: #61dafb;
}

.topleft {
	position:absolute;
	top: -300px;
	right: 1100px;
}

.topright {
	position:absolute;
	top: -300px;
	right: -1000px;
	transform: rotateY(180deg);
}

.bottomleft {
	position:absolute;
	top: 300px;
	right: 1100px;
}

.bottomright {
	position:absolute;
	top: 300px;
	right: -1000px;
	transform: rotateY(180deg);
}

/*.topleft {
	position:absolute;
	top: -40%;
	right: 200%;
}

.topright {
	position:absolute;
	top: -40%;
	right: -200%;
	transform: rotateY(180deg);
}

.bottomleft {
	position:absolute;
	top: 40%;
	right: 200%;
}

.bottomright {
	position:absolute;
	top: 40%;
	right: -200%;
	transform: rotateY(180deg);
}

@keyframes fall {
	0%
	{
		top: -10%;
	}

	100%
	{
		top: 110%
	}
	

}*/




@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.Clicker {
	font-size: 50px;
}