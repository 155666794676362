.fullscreenblack {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: black;
}

.flashing {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;

	-webkit-perspective: 500px;
	perspective: 500px;

	color: black;

	height: 250px;

	font-family: 'Oswald', sans-serif;
	font-size: 9em;
	line-height: 1;
	margin: 0;
	padding: 0;

	-webkit-transform: rotateX(25deg) rotateY(20deg) rotateZ(-3deg);
	transform: rotateX(25deg) rotateY(20deg) rotateZ(-3deg);


	-webkit-animation: anim 3s;
	-webkit-animation-timing-function: linear;
	-webkit-animation-iteration-count: infinite;

	animation: anim 3s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}

@-webkit-keyframes anim {
	0% {
		text-shadow: -6px 4px 0px red;
	}

	10% {
		text-shadow: 4px -6px 0px green;
	}

	20% {
		text-shadow: -9px 4px 0px blue;
	}

	30% {
		text-shadow: 4px -6px 0px yellow;
	}

	40% {
		text-shadow: -8px 4px 0px orange;
	}

	50% {
		text-shadow: 4px 5px 0px purple;
	}

	60% {
		text-shadow: -6px 4px 0px brown;
	}

	70% {
		text-shadow: 4px 7px 0px pink;
	}

	80% {
		text-shadow: -9px -4px 0px lime;
	}

	90% {
		text-shadow: 4px -6px 0px cyan;
	}

	100% {
		text-shadow: -9px 4px 0px teal;
	}
}

@keyframes anim {
	0% {
		text-shadow: -6px 4px 0px red;
	}

	10% {
		text-shadow: 4px -6px 0px green;
	}

	20% {
		text-shadow: -9px 4px 0px blue;
	}

	30% {
		text-shadow: 4px -6px 0px yellow;
	}

	40% {
		text-shadow: -8px 4px 0px orange;
	}

	50% {
		text-shadow: 4px 5px 0px purple;
	}

	60% {
		text-shadow: -6px 4px 0px brown;
	}

	70% {
		text-shadow: 4px 7px 0px pink;
	}

	80% {
		text-shadow: -9px -4px 0px lime;
	}

	90% {
		text-shadow: 4px -6px 0px cyan;
	}

	100% {
		text-shadow: -9px 4px 0px teal;
	}
}