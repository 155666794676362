table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  padding: 8px;
	padding-left: 40px;
}


#countdown {
	color: red
}